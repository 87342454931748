"use client";

import React from "react";
import { Spin } from "antd";

const LoadingBox = ({ minHeight = 300 }: { minHeight?: number }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        minHeight,
        width: "100%",
      }}
    >
      <Spin
        size="default"
        // indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      />
    </div>
  );
};

export default LoadingBox;
