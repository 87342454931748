import { usePathname, useSearchParams } from "next/navigation";

export default (url: string) => {
  if (typeof window === "undefined") return url;

  const base = window.location.origin;
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const redirectUrl = new URL(pathName, base);

  redirectUrl.search = new URLSearchParams(searchParams).toString();

  return `${url}?redirectUrl=${redirectUrl}`;
};
