import React from "react";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import useCommonLayout from "@koble/ui/src/CommonLayout/useCommonLayout";
import { Menu, Popover, Tour, TourProps } from "antd";

import useUserStudentExplorePage from "@/app/explore/[[...slug]]/useUserStudentExplorePage";
import useUserStudentProfile from "@/common/UserStudentProfileContext/useUserStudentProfile";

const UserStudentExploreTour = () => {
  const { userStudent } = useUserStudentProfile();
  const { markTourAsTaken, jobs, thirdPartyJobs } = useUserStudentExplorePage();
  const { upperMenuItemsWithBadges } = useCommonLayout();
  const { isMobile } = useIsMobile();

  const [openPopover, setOpenPopover] = React.useState(false);
  const numberOfJobs = jobs.length + thirdPartyJobs.length;

  const steps: TourProps["steps"] = [];
  const stepPlacement = isMobile ? "bottom" : "right";
  const stepStyle = isMobile
    ? { maxWidth: 320, paddingLeft: 10, paddingRight: 10 }
    : {};

  if (!userStudent || userStudent.userStudentTour?.hasTakenExploreTour)
    return null;

  if (isMobile) {
    steps.push({
      title: "Menú principal",
      description: "En este menú están las funciones más importantes de Koble.",
      target: () =>
        document.querySelector(
          "#common-layout-header-menu-icon"
        ) as HTMLElement,
      nextButtonProps: { onClick: () => setOpenPopover(true) },
      placement: stepPlacement,
      style: stepStyle,
    });
  }

  steps.push({
    title: "Explora vacantes",
    description:
      "Aquí encontrarás todos los trabajos disponibles a los que podrás aplicar.",
    target: () => {
      if (isMobile) {
        return document.querySelector(
          "#popover-menu .ant-menu-item:nth-child(1)"
        ) as HTMLElement;
      }

      return document.querySelector(
        "#common-layout-side-bar-menu .ant-menu-item:nth-child(1)"
      ) as HTMLElement;
    },
    prevButtonProps: {
      onClick: () => isMobile && setOpenPopover(false),
    },
    nextButtonProps: { onClick: () => setOpenPopover(false) },
    placement: stepPlacement,
    style: stepStyle,
  });

  steps.push({
    title: "Usa los filtros",
    description:
      "Refina tu búsqueda para encontrar el trabajo que más se ajuste a tus aspiraciones y posibilidades.",
    target: () => {
      return document.querySelector(
        "#user-student-explore-filters"
      ) as HTMLElement;
    },
    prevButtonProps: { onClick: () => setOpenPopover(true) },
    nextButtonProps: {
      onClick: () => numberOfJobs === 0 && isMobile && setOpenPopover(true),
    },
    placement: stepPlacement,
    style: isMobile
      ? { maxWidth: 320, paddingLeft: 10, paddingRight: 10 }
      : { maxWidth: 400 },
  });

  if (numberOfJobs > 0) {
    steps.push({
      title: "Aplica a cualquier a trabajo dándole Like",
      description:
        "Si encuentras un trabajo que te guste, dale Like para que el reclutador pueda saber que estás interesado en trabajar para su empresa. ",
      target: () => {
        return document.querySelector(
          "#user-student-explore-like-button"
        ) as HTMLElement;
      },
      nextButtonProps: { onClick: () => setOpenPopover(true) },
      placement: stepPlacement,
      style: stepStyle,
    });
  }

  steps.push({
    title: "Revisa quiénes te han dado Like",
    description:
      "Aquí podrás ver a los reclutadores interesados en contratarte. Si te gusta algún trabajo, ¡regrésales el Like!",
    target: () => {
      if (isMobile) {
        return document.querySelector(
          "#popover-menu .ant-menu-item:nth-child(2)"
        ) as HTMLElement;
      }

      return document.querySelector(
        "#common-layout-side-bar-menu .ant-menu-item:nth-child(2)"
      ) as HTMLElement;
    },
    prevButtonProps: { onClick: () => setOpenPopover(false) },
    placement: stepPlacement,
    style: stepStyle,
  });

  steps.push({
    title: "Consulta tus Matches",
    description:
      "Da seguimiento a las oportunidades laborales que te han regresado el Like y contáctalas.",
    target: () => {
      if (isMobile) {
        return document.querySelector(
          "#popover-menu .ant-menu-item:nth-child(3)"
        ) as HTMLElement;
      }

      return document.querySelector(
        "#common-layout-side-bar-menu .ant-menu-item:nth-child(3)"
      ) as HTMLElement;
    },
    placement: stepPlacement,
    style: stepStyle,
  });

  steps.push({
    title: "Chatea con los reclutadores",
    description:
      "Manten activa tus conversaciones con tus Matches para que puedas participar en el proceso de selección. ",
    target: () => {
      if (isMobile) {
        return document.querySelector(
          "#popover-menu .ant-menu-item:nth-child(4)"
        ) as HTMLElement;
      }

      return document.querySelector(
        "#common-layout-side-bar-menu .ant-menu-item:nth-child(4)"
      ) as HTMLElement;
    },
    placement: stepPlacement,
    style: stepStyle,
  });

  return (
    <>
      {isMobile && openPopover && (
        <Popover
          id="popover-menu"
          content={
            <div>
              <Menu
                style={{ border: "none" }}
                items={upperMenuItemsWithBadges}
              />
            </div>
          }
          open
          overlayStyle={{
            position: "absolute",
            top: 51,
            left: 25,
            zIndex: 100,
          }}
          placement="bottomLeft"
        />
      )}

      <Tour
        open={!userStudent.userStudentTour?.hasTakenExploreTour}
        steps={steps}
        closeIcon={false}
        onFinish={async () => {
          await markTourAsTaken("HAS_TAKEN_EXPLORE_TOUR");
          setOpenPopover(false);
        }}
      />
    </>
  );
};

export default UserStudentExploreTour;
