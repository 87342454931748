import React from "react";
import { Avatar, Flex, Typography } from "antd";

import useCommonThirdPartyJobCard from "./useCommonThirdPartyJobCard";

const { Title, Text } = Typography;

const CommonThirdPartyJobCardHeader = () => {
  const { thirdPartyJob, profileImagePlaceholder } =
    useCommonThirdPartyJobCard();

  return (
    <Flex justify="space-between" style={{ marginBottom: 10 }}>
      <Avatar
        shape="square"
        size={64}
        src={thirdPartyJob.businessProfileImageUrl ?? profileImagePlaceholder}
        style={{
          marginRight: 8,
          border: "1px solid #f0f0f0",
          borderColor: "#e8e8e8",
        }}
      />
      <div style={{ flex: 1, minWidth: 0 }}>
        <Title level={5} style={{ marginBottom: 0 }}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {thirdPartyJob.title}
          </div>
        </Title>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 13,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {thirdPartyJob.businessName ?? ""}
          </div>
        </div>
        {thirdPartyJob.salaryRange && (
          <Text style={{ fontSize: 13 }}>
            {thirdPartyJob.salaryRange?.spanishName}{" "}
            {thirdPartyJob.salaryRange?.spanishName === "Negociable"
              ? ""
              : "Mensual"}
          </Text>
        )}
      </div>
    </Flex>
  );
};

export default CommonThirdPartyJobCardHeader;
