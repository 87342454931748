"use client";

import { createContext, ReactNode } from "react";
import { ThirdPartyJob } from "@koble/graphql";

export interface CommonThirdPartyJobCardContextProps {
  thirdPartyJob: ThirdPartyJob;
  profileImagePlaceholder: string;
  likeAction?: ReactNode;
}

const CommonThirdPartyJobCardContext =
  createContext<CommonThirdPartyJobCardContextProps>({
    thirdPartyJob: {} as ThirdPartyJob,
    profileImagePlaceholder: "",
  });

export default CommonThirdPartyJobCardContext;
