import React, { ReactNode } from "react";
import { Tag, Tooltip, Typography } from "antd";

import useCommonThirdPartyJobCard from "./useCommonThirdPartyJobCard";

const { Text } = Typography;
const CommonThirdPartyJobCardBody = () => {
  const { thirdPartyJob } = useCommonThirdPartyJobCard();

  const lineHeights = 1.4;
  const fontSize = 12;
  const numberOfLines = 2;
  const spanHeight = fontSize * numberOfLines * lineHeights;

  const commonSkillsTags = () => {
    const maxTagSize = 100;
    const maxTagShow = 2;

    const tags: ReactNode[] = [];

    if (thirdPartyJob.skillInCommonWithUserStudentId === undefined) {
      return <></>;
    }

    const getTag = (id: string, label: string) => {
      return (
        <Tooltip title={label} key={id}>
          <Tag style={{ maxWidth: maxTagSize, backgroundColor: "white" }}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {label}
            </div>
          </Tag>
        </Tooltip>
      );
    };

    thirdPartyJob.skillInCommonWithUserStudentId?.forEach((skill) => {
      tags.push(getTag(skill.skillId, skill.spanishName));
    });

    if (tags.length === 0) {
      return (
        <Tag
          style={{
            backgroundColor: "white",
          }}
        >
          No hay habilidades en común
        </Tag>
      );
    }

    if (tags.length > maxTagShow) {
      return [
        tags.slice(0, maxTagShow),
        <Tooltip title={tags.slice(maxTagShow)} color="#FFF" key="more">
          <Tag style={{ maxWidth: maxTagSize, backgroundColor: "white" }}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              +{tags.length - maxTagShow}
            </div>
          </Tag>
        </Tooltip>,
      ];
    }
    return tags.slice(0, maxTagShow);
  };

  return (
    <div>
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          fontSize,
          lineHeight: `${lineHeights}em`,
          height: `${spanHeight}px`, // Fixed height for 2 lines
          width: "100%", // Ensuring the span takes the full width
        }}
      >
        {thirdPartyJob.about}
      </span>
      {thirdPartyJob.skillInCommonWithUserStudentId && (
        <div style={{ marginTop: 6 }}>
          <Text
            style={{
              fontSize: 12,
              fontWeight: 600,
              marginLeft: 0,
            }}
          >
            Aptitudes en común:
          </Text>
          <div style={{ height: 5 }} />
          {commonSkillsTags()}
        </div>
      )}
    </div>
  );
};

export default CommonThirdPartyJobCardBody;
