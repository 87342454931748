"use client";

import React, { useMemo } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { assets } from "@koble/assets/assets";
import { KobleErrors } from "@koble/errors";
import {
  GetThirdPartyJobUrlDocument,
  Job,
  ThirdPartyJob,
} from "@koble/graphql";
import { useAuth, useGraphQLRequest, useMessage, useModal } from "@koble/hooks";
import { DesktopVisible, MobileVisible } from "@koble/ui";
import { LikeButton } from "@koble/ui/src/CommonButton";
import CommonEmptyContainer from "@koble/ui/src/CommonEmptyContainer";
import CommonJobCard from "@koble/ui/src/CommonJob/CommonJobCard/CommonJobCard";
import CommonJobCardSkeleton from "@koble/ui/src/CommonJob/CommonJobCardSkeleton";
import displayMatchModal from "@koble/ui/src/CommonMatches/displayMatchModal";
import CommonThirdPartyJobCard from "@koble/ui/src/CommonThirdPartyJob/CommonThirdPartyJobCard/CommonThirdPartyJobCard";
import CommonThirdPartyJobCardSkeleton from "@koble/ui/src/CommonThirdPartyJob/CommonThirdPartyJobCardSkeleton";
import getThirdPartyJobPlatformAssets from "@koble/utils/src/getThirdPartyJobPlatformAssets";
import getUrlWithRedirectUrl from "@koble/utils/src/getUrlWithRedirectUrl";
import {
  displayGraphqlErrors,
  parseGraphqlErrors,
} from "@koble/utils/src/GraphQL";
import { createUrl } from "@koble/utils/src/urlParsing";
import {
  Button,
  Card,
  Col,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { ClientError } from "graphql-request";
import { useRouter } from "next/navigation";

import UserStudentExploreFilters from "@/app/explore/[[...slug]]/UserStudentExploreFilters";
import UserStudentExploreTour from "@/app/explore/[[...slug]]/UserStudentExploreTour";
import useUserStudentExplorePage from "@/app/explore/[[...slug]]/useUserStudentExplorePage";
import displayUserStudentInvisibleProfileModal from "@/common/displayUserStudentInvisibleProfileModal";
import useUserStudentProfile from "@/common/UserStudentProfileContext/useUserStudentProfile";
import config from "@/config";

const { Title } = Typography;

const UserStudentExplorePage = () => {
  const {
    likedJobs,
    jobs,
    thirdPartyJobs,
    createLike,
    likingJobId,
    matches,
    count,
    page,
    limit,
    area,
    benefit,
    city,
    workMode,
    workScheduleType,
    loading,
    setLoading,
  } = useUserStudentExplorePage();
  const { userStudent } = useUserStudentProfile();
  const { identityClaims } = useAuth();
  const { modalApi } = useModal();
  const { authenticatedGraphQLRequestClient } = useGraphQLRequest();
  const { messageApi } = useMessage();
  const router = useRouter();

  // TODO: properly type this
  const likedJobsObject: any = useMemo(() => {
    return likedJobs.reduce((acc, job) => {
      // Assuming each job in likedJobs is an object with a jobId property
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[job.jobId] = true; // Set the value of each jobId key to true
      return acc;
    }, {});
  }, [likedJobs]);

  const matchedJobs: any = useMemo(() => {
    return matches.reduce((acc, match) => {
      // Assuming each job in likedJobs is an object with a jobId property
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[match.job?.jobId] = true; // Set the value of each jobId key to true
      return acc;
    }, {});
  }, [matches]);

  const ExplorePagination = ({ size }: { size?: "default" | "small" }) => (
    <Pagination
      size={size}
      current={page}
      pageSize={limit}
      showSizeChanger={false}
      total={count}
      onChange={(p) => {
        const params: any = {
          pagina: p,
        };

        if (area) params.area = area.spanishName;
        if (benefit) params.beneficio = benefit.spanishName;
        if (city) params.ciudad = city.name;
        if (workMode) params.modalidad = workMode.spanishName;
        if (workScheduleType) params.horario = workScheduleType.spanishName;
        if (limit) params.limite = limit;

        const newSlug = createUrl(params);

        setLoading(true);
        router.push(`/explore/${newSlug}`);
      }}
    />
  );

  const ExplorePageSizeSelector = () => {
    let pageSizes = [6, 12, 24, 36];

    // Add the current limit to the pageSizes array if it's not already there
    if (!pageSizes.includes(limit)) {
      pageSizes = [limit, ...pageSizes];

      // Sort the pageSizes array
      pageSizes.sort((a, b) => a - b);
    }

    return (
      <Space>
        <Typography.Text>Mostar:</Typography.Text>
        <Select
          value={limit.toString()}
          onChange={(value) => {
            const params: any = {
              limite: value,
            };

            if (area) params.area = area.spanishName;
            if (benefit) params.beneficio = benefit.spanishName;
            if (city) params.ciudad = city.name;
            if (workMode) params.modalidad = workMode.spanishName;
            if (workScheduleType) params.horario = workScheduleType.spanishName;
            if (page) params.pagina = 1;

            const newSlug = createUrl(params);

            setLoading(true);
            router.push(`/explore/${newSlug}`);
          }}
          options={pageSizes.map((size) => ({
            value: size.toString(),
            label: `${size} por página`,
          }))}
        />
      </Space>
    );
  };

  const emptyFilteredJob = (
    <Card
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 500,
      }}
    >
      <CommonEmptyContainer
        img={assets.KOBOT_SAD_LARGE}
        title="¡No se encontraron vacantes que coincidan con tus filtros!"
        subtitle="Intenta con otros filtros o revisa más tarde."
        alt="No se encontraron vacantes que coincidan con tus filtros"
      />
    </Card>
  );

  const likeButtonOnClick = async (job: Job) => {
    if (!userStudent) return;

    if (userStudent.isProfileVisible) {
      const match = await createLike(job.jobId);

      if (match) {
        displayMatchModal({
          modalApi,
          job: match.job,
          userStudent: match.userStudent,
          chatId: match.chatId,
          jobProfileImagePlaceholder: assets.PLACEHOLDER_SQUARE,
          userStudentProfileImagePlaceholder: assets.PLACEHOLDER_SQUARE,
        });
      }
    } else {
      displayUserStudentInvisibleProfileModal({
        modalApi,
        userStudent,
        title: "¡Completa tu perfil para poder dar likes!",
        subtitle:
          "Para poder dar like a las vacantes que te interesen, es necesario que completes tu perfil.",
      });
    }
  };

  const getLikeActionButton = (job: Job) => {
    if (identityClaims === undefined) {
      return (
        <a href={getUrlWithRedirectUrl(config.NEXT_PUBLIC_SSO_FRONTEND)}>
          <LikeButton
            likeButtonStatus="notLiked"
            id="user-student-explore-like-button"
          />
        </a>
      );
    }

    const isLiked = likedJobsObject[job.jobId];
    const isMatched = matchedJobs[job.jobId];
    const isLikingJob = likingJobId === job.jobId;

    if (isLiked) {
      return (
        <LikeButton
          likeButtonStatus="liked"
          id="user-student-explore-like-button"
        />
      );
    }

    if (isMatched) {
      return (
        <LikeButton
          likeButtonStatus="matched"
          id="user-student-explore-like-button"
        />
      );
    }

    return (
      <LikeButton
        likeButtonStatus="notLiked"
        loading={isLikingJob}
        onClick={() => likeButtonOnClick(job)}
        id="user-student-explore-like-button"
      />
    );
  };

  const thirdPartyJobLikeButton = (thirdPartyJob: ThirdPartyJob) => {
    if (identityClaims === undefined || !userStudent) {
      return (
        <a
          href={getUrlWithRedirectUrl(config.NEXT_PUBLIC_SSO_FRONTEND)}
          style={{ width: "100%" }}
        >
          <Button
            block
            icon={<ArrowRightOutlined />}
            id="user-student-explore-like-button"
            type="default"
            style={{
              border: `1px solid ${thirdPartyJob.thirdPartyJobPlatform.colorHex}`,
              color: thirdPartyJob.thirdPartyJobPlatform.colorHex,
            }}
          >
            Ver en {thirdPartyJob.thirdPartyJobPlatform.name}
          </Button>
        </a>
      );
    }

    return (
      <Button
        block
        icon={<ArrowRightOutlined />}
        id="user-student-explore-like-button"
        type="default"
        style={{
          border: `1px solid ${thirdPartyJob.thirdPartyJobPlatform.colorHex}`,
          color: thirdPartyJob.thirdPartyJobPlatform.colorHex,
        }}
        onClick={async () => {
          try {
            const getThirdPartyJobUrl =
              await authenticatedGraphQLRequestClient?.request(
                GetThirdPartyJobUrlDocument,
                {
                  thirdPartyJobId: thirdPartyJob.thirdPartyJobId,
                }
              );

            // open the third party job url on a new tab
            if (
              getThirdPartyJobUrl &&
              getThirdPartyJobUrl.getThirdPartyJobUrl
            ) {
              window.open(getThirdPartyJobUrl.getThirdPartyJobUrl, "_blank");
            }
          } catch (e) {
            const graphQLErrors = parseGraphqlErrors(e as ClientError);

            // If the user has no clicks left, show the invisible profile modal
            if (
              graphQLErrors.find(
                (error) =>
                  error.code ===
                  KobleErrors.USER_STUDENT_THIRD_PARTY_JOB_NO_CLICKS_LEFT.key
              )
            ) {
              displayUserStudentInvisibleProfileModal({
                modalApi,
                userStudent,
                title: "¡Completa tu perfil para seguir viendo vacantes!",
                subtitle:
                  "Para poder ver las vacantes que te interesen, es necesario que completes tu perfil.",
              });
            } else {
              await displayGraphqlErrors(graphQLErrors, messageApi);
            }
          }
        }}
      >
        Ver en {thirdPartyJob.thirdPartyJobPlatform.name}
      </Button>
    );
  };

  const Jobs = jobs.map((job) => {
    return (
      <Col key={job.jobId} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
        <CommonJobCard
          job={job}
          profileImagePlaceholder={assets.PLACEHOLDER_SQUARE}
          extraContent={getLikeActionButton(job)}
          jobLinkTo={`/job/${job.jobId}`}
        />
      </Col>
    );
  });

  const thirdPartyJobsCards = thirdPartyJobs.map((thirdPartyJob) => {
    return (
      <Col
        key={thirdPartyJob.thirdPartyJobId}
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={8}
        xxl={6}
      >
        <CommonThirdPartyJobCard
          thirdPartyJob={thirdPartyJob}
          profileImagePlaceholder={getThirdPartyJobPlatformAssets(
            thirdPartyJob.thirdPartyJobPlatform
          )}
          likeAction={
            thirdPartyJob.thirdPartyJobPlatform &&
            thirdPartyJobLikeButton(thirdPartyJob)
          }
        />
      </Col>
    );
  });

  return (
    <div>
      <UserStudentExploreTour />
      <Title level={2} style={{ marginBottom: 20, marginTop: 0 }}>
        Explora vacantes
      </Title>
      <UserStudentExploreFilters />
      <div
        style={{ marginTop: 15, color: "#545454", fontSize: 12, marginLeft: 3 }}
      >
        <span>
          {count}
          {count === 1 ? " resultado" : " resultados"}
        </span>
      </div>

      <div>
        {loading && (
          <Row gutter={[16, 16]} style={{ marginTop: 14 }}>
            {jobs.map((job) => (
              <Col
                key={job.jobId}
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={6}
              >
                <CommonJobCardSkeleton job={job} />
              </Col>
            ))}
            {thirdPartyJobs.map((thirdPartyJob) => (
              <Col
                key={thirdPartyJob.thirdPartyJobId}
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={6}
              >
                <CommonThirdPartyJobCardSkeleton
                  thirdPartyJob={thirdPartyJob}
                />
              </Col>
            ))}
          </Row>
        )}

        {!loading &&
          (jobs.length + thirdPartyJobs.length === 0 ? (
            emptyFilteredJob
          ) : (
            <Row gutter={[16, 16]} style={{ marginTop: 14 }}>
              {Jobs}
              {thirdPartyJobsCards}
            </Row>
          ))}
      </div>
      <DesktopVisible>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
            position: "relative",
          }}
        >
          <div style={{ flex: 1 }} />
          <ExplorePagination />
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <ExplorePageSizeSelector />
          </div>
        </div>
      </DesktopVisible>
      <MobileVisible>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 17,
          }}
        >
          <ExplorePagination size="small" />
          <div
            style={{
              marginTop: 17,
            }}
          >
            <ExplorePageSizeSelector />
          </div>
        </div>
      </MobileVisible>
    </div>
  );
};

export default UserStudentExplorePage;
