const config = {
  NEXT_PUBLIC_IDENTITY_SERVER: "https://identity.koble.mx",
  NEXT_PUBLIC_GRAPHQL: "https://graphql.koble.mx/graphql",
  NEXT_PUBLIC_API: "https://api.koble.mx",
  NEXT_PUBLIC_WS: "wss://ws.koble.mx",
  NEXT_PUBLIC_SSO_FRONTEND: "https://sso.koble.mx",
  NEXT_PUBLIC_USER_STUDENT_FRONTEND: "https://student.koble.mx",
  NEXT_PUBLIC_USER_RECRUITER_FRONTEND: "https://recruiter.koble.mx",
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: "AIzaSyCFMGQw8H_NbaWlpWyQ4MSgLyDV5EdS_20",
  MIX_PANEL_TOKEN: "65e15c06dcbef42cb3e70a05e1c09562",
  FACEBOOK_PIXEL_CODE: 7544537748965734,
};

export default config;
