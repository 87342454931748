import React, { CSSProperties, ReactNode } from "react";
import { Avatar, Card, Col, Row, Typography } from "antd";

const { Title, Text } = Typography;

const DesktopVisible = ({
  children,
  style,
}: {
  children: ReactNode;
  style?: CSSProperties;
}) => (
  <Row style={style}>
    <Col xs={0} lg={24}>
      {children}
    </Col>
  </Row>
);

const MobileVisible = ({
  children,
  style,
}: {
  children: ReactNode;
  style?: CSSProperties;
}) => (
  <Row style={style}>
    <Col xs={24} lg={0}>
      {children}
    </Col>
  </Row>
);

const CardBodyContainer = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <DesktopVisible>
        <div style={{ paddingRight: 150 }}>{children}</div>
      </DesktopVisible>
      <MobileVisible>{children}</MobileVisible>
    </>
  );
};

const CommonLikesReceivedStepper = ({
  avatarSrc,
  bgImageSrc,
  title,
  description,
  content,
}: {
  avatarSrc: string;
  bgImageSrc: string;
  title: string;
  description: string;
  content: React.ReactNode;
}) => {
  return (
    <Card
      styles={{ body: { padding: 20 } }}
      style={{ marginBottom: 15, minHeight: 250, overflow: "hidden" }}
    >
      <CardBodyContainer>
        <div
          style={{
            display: "flex",
            columnGap: 10,
            alignItems: "center",
          }}
        >
          <Avatar
            src={avatarSrc}
            size={50}
            style={{ minWidth: 50 }}
            alt="Kobot"
          />
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              {title}
            </Title>
            <Text>{description}</Text>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: 800,
            marginTop: 30,
          }}
        >
          {content}
        </div>
      </CardBodyContainer>

      <DesktopVisible style={{ position: "absolute", top: 0, right: 0 }}>
        <div
          style={{
            position: "absolute",
            top: 47,
            right: -56,
            backgroundImage: `url("${bgImageSrc}")`,
            width: 352,
            height: 201,
            borderRadius: 8,
          }}
        />
      </DesktopVisible>
    </Card>
  );
};

export default CommonLikesReceivedStepper;
