"use client";

import { createContext } from "react";
import {
  Area,
  Benefit,
  City,
  FilteredAreas,
  FilteredBenefits,
  FilteredCities,
  FilteredWorkModes,
  FilteredWorkScheduleTypes,
  Job,
  Match,
  ThirdPartyJob,
  UserStudent,
  WorkMode,
  WorkScheduleType,
} from "@koble/graphql";

export interface UserStudentExplorePageContextProps {
  count: number;
  createLike: (
    jobId: string
  ) => Promise<{ job: Job; userStudent: UserStudent; chatId: string } | null>;
  filteredAreas: FilteredAreas[];
  filteredBenefits: FilteredBenefits[];
  filteredCities: FilteredCities[];
  filteredWorkModes: FilteredWorkModes[];
  filteredWorkScheduleTypes: FilteredWorkScheduleTypes[];
  area?: Area;
  benefit?: Benefit;
  city?: City;
  workScheduleType?: WorkScheduleType;
  workMode?: WorkMode;
  jobs: Job[];
  thirdPartyJobs: ThirdPartyJob[];
  likedJobs: Job[];
  likingJobId?: string;
  limit: number;
  matches: Match[];
  page: number;
  setLikingJobId: (jobId: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  markTourAsTaken: (tour: string) => void;
}

const UserStudentExplorePageContext =
  createContext<UserStudentExplorePageContextProps>({
    jobs: [],
    thirdPartyJobs: [],
    likedJobs: [],
    matches: [],
    likingJobId: undefined,
    createLike: async () => ({} as any), // Assuming default implementation
    setLikingJobId: () => {},
    filteredAreas: [],
    filteredBenefits: [],
    filteredCities: [],
    filteredWorkModes: [],
    filteredWorkScheduleTypes: [],
    area: undefined,
    benefit: undefined,
    city: undefined,
    workScheduleType: undefined,
    workMode: undefined,
    count: 0,
    limit: 0,
    page: 0,
    loading: false,
    setLoading: () => {},
    markTourAsTaken: () => {},
  });

// Export the context
export default UserStudentExplorePageContext;
