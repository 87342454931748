import React, { CSSProperties, ReactNode } from "react";
import { Col, Row } from "antd";

interface DesktopVisibleProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  style?: CSSProperties;
}
const DesktopVisible: React.FC<DesktopVisibleProps> = ({
  children,
  style,
  ...restProps
}) => {
  return (
    <Row style={style} {...restProps}>
      <Col xs={{ span: 0 }} md={{ span: 24 }}>
        {/* <div {...restProps}>{children}</div> */}
        {children}
      </Col>
    </Row>
  );
};

export default DesktopVisible;
