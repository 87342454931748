"use client";

import { useContext } from "react";

import CommonThirdPartyJobCardContext from "./CommonThirdPartyJobCardContext";

const useCommonThirdPartyJobCard = () =>
  useContext(CommonThirdPartyJobCardContext);

export default useCommonThirdPartyJobCard;
