import React from "react";
import { Job } from "@koble/graphql";
import { Card, Flex, Skeleton } from "antd";

const CommonJobCardSkeleton = ({ job }: { job: Job }) => {
  return (
    <Card styles={{ body: { padding: 15 } }}>
      <Skeleton.Button
        active
        shape="square"
        style={{ width: 140, height: 22, marginBottom: 10 }}
      />

      <Flex gap={8} style={{ width: "100%", marginBottom: 10.422 }}>
        <Skeleton.Avatar
          active
          shape="square"
          style={{ height: 62, width: 62, borderRadius: 6 }}
        />

        <Flex vertical style={{ flex: "auto" }}>
          {[1, 2, 3].map((value) => (
            <Skeleton.Button
              key={value}
              active
              shape="square"
              style={{
                width: "80%",
                height: 15,
                margin: "1.5px 0",
              }}
            />
          ))}
        </Flex>
      </Flex>

      <Flex vertical>
        <Skeleton.Button
          active
          block
          shape="square"
          style={{ height: 33.594 }}
        />

        {job.skillInCommonWithUserStudentId && (
          <>
            <div style={{ height: 12 }} />
            <Skeleton.Button
              active
              shape="square"
              style={{ width: 130, height: 17 }}
            />
            <Skeleton.Button
              active
              shape="square"
              style={{ width: "80%", height: 22 }}
            />
          </>
        )}

        <div style={{ height: 18 }} />

        <Flex justify="space-between" style={{ marginBottom: 8 }}>
          {[1, 2].map((value) => (
            <Skeleton.Button
              key={value}
              active
              shape="square"
              style={{ width: 100, height: 17 }}
            />
          ))}
        </Flex>

        <Skeleton.Button active block shape="square" style={{ height: 32 }} />
      </Flex>
    </Card>
  );
};

export default CommonJobCardSkeleton;
