"use client";

import { useContext } from "react";

import UserStudentExplorePageContext from "./UserStudentExplorePageContext";

const useUserStudentExplorePage = () =>
  useContext(UserStudentExplorePageContext);

export default useUserStudentExplorePage;
