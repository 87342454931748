import React, { ReactNode } from "react";

import CommonThirdPartyJobCardContext, {
  CommonThirdPartyJobCardContextProps,
} from "./CommonThirdPartyJobCardContext";

const CommonThirdPartyJobCardProvider = ({
  children,
  ...props
}: CommonThirdPartyJobCardContextProps & { children: ReactNode }) => {
  return (
    <CommonThirdPartyJobCardContext.Provider value={{ ...props }}>
      {children}
    </CommonThirdPartyJobCardContext.Provider>
  );
};

export default CommonThirdPartyJobCardProvider;
