import {
  Area,
  Benefit,
  City,
  FilteredAreas,
  FilteredBenefits,
  FilteredCities,
  FilteredWorkModes,
  FilteredWorkScheduleTypes,
  WorkMode,
  WorkScheduleType,
} from "@koble/graphql";
import CatalogFilter from "@koble/ui/src/CatalogFilter";
import { createUrl } from "@koble/utils/src/urlParsing";
import { Button, Space } from "antd";
import { usePathname, useRouter } from "next/navigation";

import useUserStudentExplorePage from "@/app/explore/[[...slug]]/useUserStudentExplorePage";

const UserStudentExploreFilters = () => {
  const {
    filteredAreas,
    filteredBenefits,
    filteredCities,
    filteredWorkModes,
    filteredWorkScheduleTypes,
    area,
    benefit,
    city,
    workScheduleType,
    workMode,
    limit,
    setLoading,
  } = useUserStudentExplorePage();
  const router = useRouter();
  const pathName = usePathname();

  return (
    <Space wrap id="user-student-explore-filters">
      <CatalogFilter<FilteredAreas, Area>
        items={filteredAreas}
        idKey="areaId"
        labelKey="spanishName"
        objectKey="area"
        defaultValue={area}
        onChange={(value) => {
          const params: any = {
            pagina: 1,
            limite: limit,
          };

          if (value) params.area = value.spanishName;
          if (benefit) params.beneficio = benefit.spanishName;
          if (city) params.ciudad = city.name;
          if (workScheduleType) params.horario = workScheduleType.spanishName;
          if (workMode) params.modalidad = workMode.spanishName;

          const newSlug = createUrl(params);
          const newPath = `/explore/${newSlug}`;

          if (pathName !== newPath) {
            setLoading(true);
            router.push(newPath);
          }
        }}
        title="Area"
      />
      <CatalogFilter<FilteredBenefits, Benefit>
        items={filteredBenefits}
        idKey="benefitId"
        labelKey="spanishName"
        objectKey="benefit"
        defaultValue={benefit}
        onChange={(value) => {
          const params: any = {
            pagina: 1,
            limite: limit,
          };

          if (value) params.beneficio = value.spanishName;
          if (area) params.area = area.spanishName;
          if (city) params.ciudad = city.name;
          if (workScheduleType) params.horario = workScheduleType.spanishName;
          if (workMode) params.modalidad = workMode.spanishName;

          const newSlug = createUrl(params);
          const newPath = `/explore/${newSlug}`;

          if (pathName !== newPath) {
            setLoading(true);
            router.push(newPath);
          }
        }}
        title="Beneficio"
      />
      <CatalogFilter<FilteredCities, City>
        items={filteredCities}
        idKey="cityId"
        labelKey="name"
        objectKey="city"
        defaultValue={city}
        onChange={(value) => {
          const params: any = {
            pagina: 1,
            limite: limit,
          };

          if (value) params.ciudad = value.name;
          if (area) params.area = area.spanishName;
          if (benefit) params.beneficio = benefit.spanishName;
          if (workScheduleType) params.horario = workScheduleType.spanishName;
          if (workMode) params.modalidad = workMode.spanishName;

          const newSlug = createUrl(params);
          const newPath = `/explore/${newSlug}`;

          if (pathName !== newPath) {
            setLoading(true);
            router.push(newPath);
          }
        }}
        title="Ciudad"
      />
      <CatalogFilter<FilteredWorkScheduleTypes, WorkScheduleType>
        items={filteredWorkScheduleTypes}
        idKey="workScheduleTypeId"
        labelKey="spanishName"
        objectKey="workScheduleType"
        defaultValue={workScheduleType}
        onChange={(value) => {
          const params: any = {
            pagina: 1,
            limite: limit,
          };

          if (value) params.horario = value.spanishName;
          if (area) params.area = area.spanishName;
          if (benefit) params.beneficio = benefit.spanishName;
          if (city) params.ciudad = city.name;
          if (workMode) params.modalidad = workMode.spanishName;

          const newSlug = createUrl(params);
          const newPath = `/explore/${newSlug}`;

          if (pathName !== newPath) {
            setLoading(true);
            router.push(newPath);
          }
        }}
        title="Horario"
      />
      <CatalogFilter<FilteredWorkModes, WorkMode>
        items={filteredWorkModes}
        idKey="workModeId"
        labelKey="spanishName"
        objectKey="workMode"
        defaultValue={workMode}
        onChange={(value) => {
          const params: any = {
            pagina: 1,
            limite: limit,
          };

          if (value) params.modalidad = value.spanishName;
          if (area) params.area = area.spanishName;
          if (benefit) params.beneficio = benefit.spanishName;
          if (city) params.ciudad = city.name;
          if (workScheduleType) params.horario = workScheduleType.spanishName;

          const newSlug = createUrl(params);
          const newPath = `/explore/${newSlug}`;

          if (pathName !== newPath) {
            setLoading(true);
            router.push(newPath);
          }
        }}
        title="Modalidad"
      />
      {(area || benefit || city || workMode || workScheduleType) && (
        <Button
          type="text"
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            fontWeight: 600,
            color: "#1677FF",
          }}
          onClick={() => {
            setLoading(true);
            router.push(`/explore`);
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </Space>
  );
};

export default UserStudentExploreFilters;
