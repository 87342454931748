import { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Divider, Popover, Radio, Space, Typography } from "antd";

const { Title } = Typography;

const CatalogFilter = <T, R>({
  objectKey,
  items,
  idKey,
  labelKey,
  defaultValue,
  onChange,
  title,
}: {
  objectKey: keyof T;
  items: T[];
  idKey: keyof R;
  labelKey: keyof R;
  defaultValue?: R;
  onChange?: (value?: R) => void;
  title: string;
}) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>(
    defaultValue ? (defaultValue as any)[idKey] : undefined
  );

  const dict = items.reduce((acc, item) => {
    acc[(item as any)[objectKey][idKey]] = item;
    return acc;
  }, {} as Record<string, T>);

  const content = (
    <div>
      <div
        style={{
          padding: 15,
          paddingBottom: 0,
        }}
      >
        <Title level={5} style={{ margin: 0 }}>
          {title}
        </Title>
      </div>
      {items.length === 0 && (
        <div
          style={{
            padding: 15,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#b2b2b2",
          }}
        >
          No hay elementos para la selección actual
        </div>
      )}
      <Space
        direction="vertical"
        style={{
          width: "100%",
          padding: 20,
          paddingRight: 15,
          paddingLeft: 15,
          paddingTop: 15,
          maxHeight: 600,
          overflow: "auto",
        }}
      >
        {items.map((c) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              gap: 10,
              cursor: "pointer",
            }}
            key={(c as any)[objectKey][idKey]}
            onClick={() => {
              setSelectedId((c as any)[objectKey][idKey]);
            }}
          >
            <Radio
              key={(c as any)[objectKey][idKey]}
              value={c}
              style={{ margin: 0 }}
              checked={selectedId === (c as any)[objectKey][idKey]}
              onChange={() => setSelectedId((c as any)[objectKey][idKey])}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              {
                (dict as any)[(c as any)[objectKey][idKey]][objectKey][
                  labelKey
                ] as any
              }
            </div>
            <div
              style={{
                color: "#b2b2b2",
                fontSize: 14,
              }}
            >
              ({(c as any).count})
            </div>
          </div>
        ))}
      </Space>
      <Divider style={{ margin: 0 }} />
      <Space
        style={{
          width: "100%",
          padding: 15,
          paddingRight: 15,
          paddingLeft: 15,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="small"
          onClick={() => {
            setSelectedId(undefined);
            onChange?.(undefined);
            setOpenPopover(false);
          }}
        >
          Limpiar
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            if (selectedId) {
              onChange?.(dict[selectedId][objectKey] as any);
              setOpenPopover(false);
            }
          }}
        >
          Aplicar
        </Button>
      </Space>
    </div>
  );

  return (
    <Popover
      overlayInnerStyle={{ padding: 0 }}
      content={content}
      trigger="click"
      placement="bottom"
      arrow={false}
      open={openPopover}
      onOpenChange={setOpenPopover}
      overlayStyle={{
        width: "100%",
        maxWidth: 350,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <Button type={defaultValue ? "primary" : "default"}>
        {defaultValue ? (defaultValue[labelKey] as any) : title}
        <DownOutlined
          style={{
            fontSize: 12,
            transform: "scale(1, 0.8)",
          }}
        />
      </Button>
    </Popover>
  );
};

export default CatalogFilter;
