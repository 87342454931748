import { assets } from "@koble/assets/assets";
import { ThirdPartyJobPlatform } from "@koble/graphql";

export default (thirdPartyJobPlatform: ThirdPartyJobPlatform | undefined) => {
  if (!thirdPartyJobPlatform) return assets.PLACEHOLDER_SQUARE;

  switch (thirdPartyJobPlatform.thirdPartyJobPlatformId) {
    case "LINKEDIN":
      return assets.LINKEDIN_LOGO;
    case "INDEED":
      return assets.INDEED_LOGO;
    case "GLASSDOOR":
      return assets.GLASSDOOR_LOGO;
    case "OCC":
      return assets.OCC_LOGO;
    default:
      return assets.PLACEHOLDER_SQUARE;
  }
};
