"use client";

import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Result } from "antd";

const ErrorBox = ({ minHeight = 300 }: { minHeight?: number }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        minHeight,
        width: "100%",
      }}
    >
      <Result
        status="error"
        subTitle={
          <div>
            Oops, algo salió mal
            <br />
            Intenta de nuevo mas tarde
          </div>
        }
        icon={
          <CloseCircleOutlined
            style={{
              fontSize: 60,
            }}
          />
        }
      />
    </div>
  );
};

export default ErrorBox;
