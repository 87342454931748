import React from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { CalendarOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";

import useCommonThirdPartyJobCard from "./useCommonThirdPartyJobCard";

const { Text } = Typography;

const CommonThirdPartyJobCardFooter = () => {
  const { likeAction, thirdPartyJob } = useCommonThirdPartyJobCard();

  const getJobLocation = () => {
    if (thirdPartyJob.place) {
      return (
        thirdPartyJob.place.city?.name ??
        thirdPartyJob.place.country?.name ??
        thirdPartyJob.place.state?.name ??
        thirdPartyJob.place.description
      );
    }

    return "Remoto";
  };

  const getJobLocationTooltip = () => {
    if (thirdPartyJob.place) {
      return thirdPartyJob.place.city?.name
        ? `${thirdPartyJob.place.city?.name}, ${thirdPartyJob.place.country?.name}`
        : `${thirdPartyJob.place.state?.name}, ${thirdPartyJob.place.country?.name}`;
    }

    return "Trabajo remoto";
  };

  const getPublishedAtText = () => {
    const currentDate = new Date();
    const publishedDate = new Date(thirdPartyJob.publishedAt);

    // Calculate the difference in days
    const differenceInDays = Math.floor(
      (currentDate.getTime() - publishedDate.getTime()) / (1000 * 60 * 60 * 24)
    );

    if (differenceInDays < 1) {
      return "Publicado hoy";
    }

    // Determine the appropriate text
    if (differenceInDays <= 70) {
      return `Publicado hace ${differenceInDays} días`;
    }
    const differenceInMonths = Math.floor(differenceInDays / 30);
    return `Publicado hace ${differenceInMonths} meses`;
  };

  const getLikeAction = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 8,
        }}
      >
        {likeAction}
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Tooltip title={getJobLocationTooltip()}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginRight: 10,
              }}
            >
              <MdOutlineLocationOn
                style={{
                  marginRight: 4,
                  fontSize: 12,
                  top: 1,
                  position: "relative",
                }}
              />
              <Text>{getJobLocation()}</Text>
            </div>
          </Tooltip>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title={getPublishedAtText()}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              <CalendarOutlined
                style={{
                  marginRight: 4,
                  fontSize: 12,
                  bottom: 1,
                  position: "relative",
                }}
              />
              <Text>{getPublishedAtText()}</Text>
            </div>
          </Tooltip>
        </div>
      </div>
      <div>{getLikeAction()}</div>
    </div>
  );
};

export default CommonThirdPartyJobCardFooter;
