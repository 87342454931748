import formatStringForURL from "./formatStringForURL";

const createUrl = (params: { [key: string]: string | number }) => {
  let str = "";

  Object.keys(params).forEach((key) => {
    if (str !== "") {
      str += "-";
    }

    if (typeof params[key] === "string") {
      const value = params[key] as string;
      const withoutAccents = formatStringForURL(value.toLowerCase());
      const withoutSpaces = withoutAccents.split(" ").join("-");

      str += `${key}-${withoutSpaces}`;
    } else {
      str += `${key}-${params[key]}`;
    }
  });

  return str;
};

export default createUrl;
