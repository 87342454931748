import stringToColor from "@koble/utils/src/stringToColor";
import { Tag } from "antd";

import useCommonThirdPartyJobCard from "./useCommonThirdPartyJobCard";

const CommonThirdPartyJobCardTags = () => {
  const { thirdPartyJob } = useCommonThirdPartyJobCard();

  return (
    <div style={{ marginBottom: 10 }}>
      <div style={{ display: "flex" }}>
        {thirdPartyJob.area && (
          <Tag color={stringToColor(thirdPartyJob.area.areaId)}>
            {thirdPartyJob.area.spanishName}
          </Tag>
        )}
      </div>
    </div>
  );
};

export default CommonThirdPartyJobCardTags;
