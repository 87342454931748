"use client";

import React, { ReactNode } from "react";
import { Card, Typography } from "antd";

import CommonEmptyContainer from "./CommonEmptyContainer";

const { Title } = Typography;
const CommonEmptyPage = ({
  title,
  subtitle,
  img,
  alt,
  extraContent,
  pageTitle,
  cardStyle,
}: {
  title: string;
  subtitle: string | ReactNode;
  img: string;
  alt: string;
  extraContent?: ReactNode;
  pageTitle: string;
  cardStyle?: React.CSSProperties;
}) => {
  return (
    <>
      <Title level={2} style={{ marginBottom: 20, marginTop: 0 }}>
        {pageTitle}
      </Title>
      <Card
        style={{
          borderRadius: 16,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          height: "70%",
          ...cardStyle,
        }}
      >
        <CommonEmptyContainer
          title={title}
          extraContent={extraContent}
          alt={alt}
          subtitle={subtitle}
          img={img}
        />
      </Card>
    </>
  );
};

export default CommonEmptyPage;
