import React, { CSSProperties, ReactNode } from "react";
import { Col, Row } from "antd";

interface MobileVisibleProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  style?: CSSProperties;
}
const MobileVisible: React.FC<MobileVisibleProps> = ({
  children,
  style,
  ...restProps
}) => {
  return (
    <Row style={style} {...restProps}>
      <Col xs={{ span: 24 }} md={{ span: 0 }}>
        {/* <div {...restProps}>{children}</div> */}
        {children}
      </Col>
    </Row>
  );
};

export default MobileVisible;
