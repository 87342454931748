import { ReactNode } from "react";
import { ThirdPartyJob } from "@koble/graphql";
import { Badge, Card, Divider } from "antd";

import CommonThirdPartyJobCardBody from "./CommonThirdPartyJobCardBody";
import CommonThirdPartyJobCardFooter from "./CommonThirdPartyJobCardFooter";
import CommonThirdPartyJobCardHeader from "./CommonThirdPartyJobCardHeader";
import CommonThirdPartyJobCardProvider from "./CommonThirdPartyJobCardProvider";
import CommonThirdPartyJobCardTags from "./CommonThirdPartyJobCardTags";

const CommonThirdPartyJobCard = ({
  thirdPartyJob,
  profileImagePlaceholder,
  likeAction,
}: {
  thirdPartyJob: ThirdPartyJob;
  profileImagePlaceholder: string;
  likeAction?: ReactNode;
}) => {
  return (
    <CommonThirdPartyJobCardProvider
      thirdPartyJob={thirdPartyJob}
      profileImagePlaceholder={profileImagePlaceholder}
      likeAction={likeAction}
    >
      <Badge.Ribbon
        style={{
          visibility: thirdPartyJob.thirdPartyJobPlatform
            ? "visible"
            : "hidden",
        }}
        text={
          !thirdPartyJob.thirdPartyJobPlatform
            ? ""
            : thirdPartyJob.thirdPartyJobPlatform.name
        }
        color={
          !thirdPartyJob.thirdPartyJobPlatform
            ? "#FFFFFF"
            : thirdPartyJob.thirdPartyJobPlatform.colorHex
        }
      >
        <Card styles={{ body: { padding: 15 } }}>
          <CommonThirdPartyJobCardTags />
          <CommonThirdPartyJobCardHeader />
          <CommonThirdPartyJobCardBody />
          <Divider style={{ marginBottom: 7, marginTop: 10 }} />
          <CommonThirdPartyJobCardFooter />
        </Card>
      </Badge.Ribbon>
    </CommonThirdPartyJobCardProvider>
  );
};

export default CommonThirdPartyJobCard;
